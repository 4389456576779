<template>
  <div>
    <div class="flex">
      <p class="font-barlow font-medium text-xl text-grey-medium-2">
        {{ title }} {{ displayVersionForSeller(productVersion) }} /
      </p>
      <p class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
        Job Roles
      </p>
      <div class="flex flex-grow justify-end">
        <Button
          text="Back"
          type="primary-white"
          size="medium"
          :iconLeft="arrowLeft"
          :rotateArrow="true"
          :onClick="goBack"
          class="mr-2"
          data-testid="back-button"
        />
        <Button
          text="Next"
          type="primary"
          :iconRight="arrowRight"
          size="medium"
          :onClick="goNext"
          :disabled="!everyoneHasJobRole"
          data-testid="next-button"
        />
      </div>
    </div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <p class="font-barlow font-bold text-mdl text-grey-light mb-2">
        Assign Job Roles to a Participant
      </p>

      <div class="px-2">
        <p class="text-mdh text-grey-dark-1 mb-3 max-w-1/2">
          The system has automatically assigned the job role based on the job
          title which was provided. By hovering over the job role or selecting
          the drop down arrow menu you can change the job role which best
          corresponds to the role in your organization. You are also able to
          apply this change to multiple individuals at one time.
        </p>
        <NoRoleCard :list="jobRoles" />
        <JobRolesTable :handler="updateJobRoleIds" v-if="loadTable" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../../../components/Button/Button.vue';
import JobRolesTable from '../components/JobRolesTable';
import { mapActions, mapState, mapGetters } from 'vuex';
import store from '@/store';
import NoRoleCard from '../components/NoRoleCard.vue';
import arrowRight from '@/assets/img/icons/arrow-right-white.svg';
import arrowLeft from '@/assets/img/icons/arrow-right.svg';

export default {
  name: 'JobRoles',
  components: { Button, JobRolesTable, NoRoleCard },
  data: () => ({ jobRoles: [], arrowRight, arrowLeft, loadTable: false }),
  computed: {
    ...mapState({
      title: (state) => state.assessmentSetup.assessmentTitle,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
      id: (state) => state.assessmentSetup.cloneId,
    }),
    ...mapGetters({
      isImpersonating: "auth/isImpersonating",
      isSeller: "auth/isSeller",
    }),
    productVersion(){
      return this.generalInfo.productVersion;
    },
    everyoneHasJobRole() {
      const list = this.jobRoles.find((el) => el.jobRoleId == null);
      return list ? false : true;
    },
  },
  async mounted() {
    await this.getJobRolesForSurvey(this.id);
    this.loadTable = true;
  },
  methods: {
    ...mapActions({
      updateJobRoles: 'assessmentSetup/updateJobRoles',
      getJobRolesDescription: 'assessmentSetup/getJobRolesDescription',
      getJobRolesForSurvey: 'assessmentSetup/getJobRolesForSurvey',
    }),
    async goNext() {
      await this.saveChanges();
      this.$router.push('/assessments/targets');
    },
    goBack() {
      this.$router.push('/assessments/participants');
    },
    displayVersionForSeller(version) {
      if (version &&  version !== "" && (this.isImpersonating || this.isSeller)) 
        return " (v" + version + ")";
      return "";
    },
    async updateJobRoleIds(val, isMounting) {
      this.jobRoles = val;
      if (!isMounting) await this.saveChanges();
    },
    async saveChanges() {
      var list = this.jobRoles.filter((item) => item.jobRoleId !== null);
      list = list.map((item) => {
        if (item.jobRoleId) {
          return { userId: item.id, jobRoleId: item.jobRoleId };
        }
      });
      await this.updateJobRoles({ id: this.id, jobRoles: list });
    },
  },

  beforeRouteEnter(to, _, next) {
    const title = store.getters['assessmentSetup/getAssessmentTitle'];
    to.meta.sidenav.title = title + ' Setup';
    next();
  },
};
</script>
