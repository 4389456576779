<template>
  <div>
    <div class="flex flex-grow justify-between items-center">
      <InputField
        placeholder="Name, job title or team"
        label="Filter by"
        :onChange="setKeyword"
        leftIcon
        class="mb-2 w-inputField"
        data-testid="filter-field"
      />
      <Button
        text="Assign Job Role"
        @click.native="assignJobRoles({ all: true, index: null })"
        :disabled="isAnySelected"
        data-testid="assign-role-button"
      />
    </div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ filteredList.length }} Participants
    </p>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 px-2 rounded">
        <button class="mr-2" @click="toggleAll">
          <Checkbox :active="selectAll" data-testid="select-all-checkbox" />
        </button>
        <div
          class="flex flex-row items-center focus:outline-none w-tableIndex mr-2"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
        </div>
        <div class="flex flex-grow w-1/5">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('name', 0)"
            data-testid="header-1"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/5">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('jobTitle', 1)"
            data-testid="header-2"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/5">
          <div class="flex flex-row items-center focus:outline-none">
            <p class="text-sm-2 text-grey-light mr-0.4">TEAM</p>
          </div>
        </div>
        <div class="flex flex-grow w-1/4">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('jobRole', 3)"
            data-testid="header-3"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">JOB ROLE</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
      </div>
      <div>
        <VirtualList
          style="max-height: 364px; min-height: 156px; overflow-y: auto"
          :data-key="'id'"
          :data-sources="filteredList"
          :data-component="itemComponent"
          :extra-props="{
            toggle: toggleItem,
            total: mappedList.length,
            assignJobRoles,
          }"
          data-testid="job-roles-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { mapState, mapActions } from "vuex";
import Checkbox from "../../../../components/Checkbox/";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import InputField from "../../../../components/InputField/InputField.vue";
import Button from "../../../../components/Button/Button.vue";
import _forEach from "lodash/forEach";

export default {
  name: "TeamTable",
  components: { Checkbox, VirtualList, InputField, Button },
  data: () => ({
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1],
    mappedList: [],
    filteredList: [],
    selectAll: false,
    sorting: null,
    assign: { all: true, index: null },
  }),
  props: { handler: { type: Function, default: () => {} } },
  computed: {
    ...mapState({
      id: (state) => state.assessmentSetup.cloneId,
      participants: (state) => state.assessmentSetup.participants,
      selectedJobRole: (state) => state.assessmentSetup.selectedJobRole,
      jobRoles: (state) => state.assessmentSetup.jobRolesForSurvey,
    }),
    isAnySelected() {
      let count = 0;
      _forEach(this.mappedList, (el) => {
        if (el.isSelected) {
          count += 1;
        }
      });
      return count === 0;
    },
  },
  async mounted() {
    await this.updateList();
    this.handler(this.mappedList, true);
    this.filteredList = this.mappedList;
  },
  watch: {
    selectedJobRole(current) {
      if (this.assign.all) {
        this.mappedList = this.mappedList.map((item) => {
          if (item.isSelected) {
            item.jobRoleName = current.name;
            item.jobRoleId = current.id;
            item.jobRoleDescription = current.description;
          }
          return item;
        });
      } else {
        this.mappedList[this.assign.index].jobRoleName = current.name;
        this.mappedList[this.assign.index].jobRoleId = current.id;
        this.mappedList[this.assign.index].jobRoleDescription =
          current.description;
      }
      this.handler(this.mappedList);
    },
    keyword() {
      this.sorting = null;
      this.filteredList = this.mappedList.filter((item) => {
        const teams = item.teams.map((item) => item.name).join(" ");
        const field = [
          item.firstName,
          item.lastName,
          item.jobTitle,
          teams,
        ].join(" ");
        return field.toLowerCase().includes(this.keyword.toLowerCase());
      });
    },
    filteredList() {
      if (
        this.filteredList.length === this.mappedList.length &&
        this.selectAll
      ) {
        this.selectAll = false;
      }
    },
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
      getParticipants: "assessmentSetup/getParticipants",
    }),
    async updateList() {
      await this.getParticipants({
        id: this.id,
        pageNr: 1,
        pageSz: 999,
        keyword: this.keyword,
        sorting: this.sorting,
      });
      let map = this.participants.map((item, idx) => {
        let found = this.jobRoles.find((el) => el.id == item.jobRoleId);
        return {
          ...item,
          index: idx,
          isSelected: false,
          jobRoleDescription: found ? found.description : null,
        };
      });
      this.mappedList = [...map];
    },
    assignJobRoles(val, jobRole) {
      this.openModal({ modal: "jobRoleModal", props: { jobRole } });
      val ? (this.assign = val) : (this.assign = { all: true, index: null });
    },
    setRotation(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx == index ? -1 * item : 1
      );
    },
    sort(key, index) {
      this.setRotation(index);
      switch (key) {
        case "name":
          this.filteredList.sort((a, b) => {
            let x = a.firstName + " " + a.lastName;
            let y = b.firstName + " " + b.lastName;
            return this.rotationMatrix[index] * x.localeCompare(y);
          });
          break;
        case "jobTitle":
          this.filteredList.sort(
            (a, b) =>
              this.rotationMatrix[index] * a.jobTitle?.localeCompare(b.jobTitle)
          );
          break;
        case "jobRole":
          this.filteredList.sort(
            (a, b) =>
              this.rotationMatrix[index] *
              a.jobRoleName?.localeCompare(b.jobRoleName) // check it again
          );
          break;
        default:
          break;
      }
    },
    setKeyword(value) {
      this.keyword = value;
    },

    toggleItem(index) {
      this.mappedList[index].isSelected = !this.mappedList[index].isSelected;
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
      this.filteredList.map((item) => (item.isSelected = this.selectAll));
    },
  },
};
</script>
