<template>
  <div :data-testid="`listitem-${index}`">
    <div
      class="flex flex-row w-full items-center px-2 h-50 hover:bg-blue-light-1"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :ref="`jobrole-row-${source.index}`"
    >
      <button class="mr-2" @click="extendToggle">
        <Checkbox
          :active="source.isSelected"
          data-testid="select-all-checkbox"
        />
      </button>
      <div class="flex w-tableIndex justify-center mr-2 pr-3">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5 h-full items-center">
        <p class="text-md text-grey-dark-1 font-semibold" v-clamp>
          {{ fullName }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5 h-full items-center">
        <p class="text-md text-grey-dark-2" v-clamp>
          {{ source.jobTitle }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5 h-full items-center">
        <p class="text-md text-grey-dark-2" v-clamp>
          {{ teams || "None" }}
        </p>
      </div>
      <div class="flex flex-grow w-1/4 items-center">
        <p
          class="text-md mr-2"
          :class="source.jobRoleName ? 'text-grey-dark-2' : 'text-red'"
        >
          {{ source.jobRoleName || "No role assigned." }}
        </p>
        <div
          class="parent relative"
          :ref="`jobrole-tooltip-${source.index}`"
          :class="source.jobRoleName ? '' : 'hidden'"
        >
          <img class="w-2 h-2" src="../../../../assets/img/icons/info.svg" />
          <JobRoleTooltip
            class="tooltip absolute"
            :jobRole="source.jobRoleName"
            :description="source.jobRoleDescription"
            :style="[horizontalRule, verticalRule]"
          />
        </div>

        <button
          v-if="hover"
          class="ml-2 bg-white p-1 focus:outline-none"
          @click="assignJobRoles({ all: false, index }, source.jobRoleName)"
          data-testid="assign-button"
        >
          <img src="../../../../assets/img/icons/edit.svg" />
        </button>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import Checkbox from "../../../../components/Checkbox/";
import JobRoleTooltip from "../JobRoleTooltip.vue";
export default {
  name: "ListItem",
  data: () => ({
    leftTooltip: false,
    topTooltip: false,
    hover: false,
  }),
  components: {
    Checkbox,
    JobRoleTooltip,
  },
  computed: {
    fullName() {
      return this.source.firstName + " " + this.source.lastName;
    },
    horizontalRule() {
      return this.leftTooltip ? { right: "0px" } : { left: "0px" };
    },
    verticalRule() {
      return this.topTooltip ? { bottom: "0px" } : { top: "0px" };
    },
    teams() {
      let string = "";
      this.source.teams.map(
        (item, index) =>
          (string +=
            item.name + (index !== this.source.teams.length - 1 ? ", " : ""))
      );
      return string;
    },
  },
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    toggle: { type: Function, default: () => {} },
    total: { type: Number, default: null },
    assignJobRoles: { type: Function },
  },
  mounted() {
    this.setTooltipPosition();
    window.addEventListener("resize", this.setTooltipPosition);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setTooltipPosition);
  },
  methods: {
    extendToggle() {
      this.toggle(this.source.index);
    },
    setTooltipPosition() {
      const elRect = this.$refs[
        `jobrole-tooltip-${this.source.index}`
      ].getBoundingClientRect();
      const elRow = this.$refs[
        `jobrole-row-${this.source.index}`
      ].getBoundingClientRect();
      const thresholdX = elRow.x + elRow.width - elRect.x; /*  */
      if (thresholdX < 260) {
        this.leftTooltip = true;
      } else {
        this.leftTooltip = false;
      }
      if (this.index >= 3 && this.index >= this.total - 2) {
        this.topTooltip = true;
      } else {
        this.topTooltip = false;
      }
    },
  },
};
</script>

<style lang="scss">
.tooltip {
  display: none;
}
.parent:hover .tooltip {
  display: block;
  z-index: 100;
  //transform: translateY(-64px) translateX(32px);

  width: 250px;
}
</style>
